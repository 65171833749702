import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo, useEffect, useState } from 'react'
import {
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { getIndicatorAngular } from '~/modules/strength-state/getIndicatorAngular'
import { PriceGauges } from './component/PriceGauges'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { proxy, useSnapshot } from 'valtio'
import { store } from '~/pages/heineken_template/_private/store'
import IntradayTrendChart from '~/modules/trendChart'
import DarkGauge from './component/DarkGauge'

import { TrendScore } from './component/TrendScore'
import { PriceBuild } from './component/PriceBuild'
import TradingPlatfrom from '../daddy960_tc1688/component/TradingPlatform'
import {
  useExtremeVolumeResource,
  usePercentRangeResource,
} from '~/modules/screener/containers/useStockScreenerResource'
import { useDatePick } from '~/modules/screener/useDatePick'
import { intersection } from 'lodash'

const watchListGroup = 'asia71178_group_1'

export const Asia71178_SidePane = memo<ReactProps>(function Asia71178_SidePane() {
  const [number, setNumber] = useState(Math.random())

  const charting = useSnapshot(store.charting)
  const symbolNumber = charting.symbol

  useEffect(() => {
    const intervalId = setInterval(() => {
      setNumber(Math.random())
    }, 2000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const marketValue = getIndicatorAngular(number * 1000) * 100

  //選股
  const date = useDatePick()

  const dailyRankData = usePercentRangeResource({
    days: 3,
    status: 'new_high',
    volume_gte: 5000,
    volume_lte: 0,
  })

  const extremeVolumeData = useExtremeVolumeResource({
    date: date,
    days: 5,
    volume_gte: 5000,
    volume_lte: 0,
    threshold: 1.25,
  })

  const dailyRankSymbol = dailyRankData.data?.map(datum => datum.symbol)
  const extremeVolumeSymbol = extremeVolumeData.data
    ?.filter(a => a.volume / a.volume_ma < 1.25 * 2)
    ?.map(datum => datum.symbol)

  const allSymbol = intersection(dailyRankSymbol, extremeVolumeSymbol)

  return (
    <styleds.container>
      {/* <PriceGauges currentValue={marketValue} /> */}
      <styleds.title>主力買盤</styleds.title>

      <styleds.componentCard height={'136px'}>
        <DarkGauge.Display
          currentValue={marketValue}
          fillType='default'
        >
          主力買盤
        </DarkGauge.Display>
      </styleds.componentCard>

      <styleds.title>趨勢得分</styleds.title>

      <styleds.componentCard height={'68px'}>
        <TrendScore />
      </styleds.componentCard>

      <styleds.componentCard height={'32px'}>
        <PriceBuild />
      </styleds.componentCard>

      <styleds.componentCard height={'164px'}>
        <TitleSymbolQuote.Default symbol={symbolNumber} />
        <div
          css={css`
            height: 134px;
          `}
        >
          <IntradayTrendChart
            symbol={symbolNumber}
            ticksSize={14}
            ticksHeight={20}
            priceTicksMargin={2.5}
            priceTicksSize={14}
          />
        </div>
      </styleds.componentCard>
      <styleds.componentCard height={'calc(100% - 476px)'}>
        <TradingPlatfrom.Display
          symbolArray={allSymbol}
          watchListGroup={watchListGroup}
        />
      </styleds.componentCard>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
    border-left: 1px solid #cccccc;
  `,
  componentCard: styled.div<{ height: string }>`
    width: 100%;
    height: ${props => props.height};
    padding: 4px;
    border-radius: 6px;
    box-shadow: 0px 1px 5px 1px #cccccc;
  `,
  title: styled.div`
    ${fill_vertical_all_center};
    height: 26px;
    border-radius: 3px;
    background-color: #e4e3e3;
  `,
  symbolList: styled.div`
    width: 100%;
    height: calc(100% - 500px);
    border-radius: 6px;
    box-shadow: 0px 1px 5px 1px #cccccc;
    padding: 4px;
  `,
}
