import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

/** MACD */
export const daddy960_tc1688_macdImpluse = createIndicator({
  id: 'daddy960-tc1688-macdImpluse',
  displayName: 'Impluse MACD',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      //#region Input params
      const masterMa = this._input(0)
      const signalMa = this._input(1)
      //#endregion

      //#region Init
      const high = this.ohlc.highArray
      const low = this.ohlc.lowArray
      const weightedClose = context.new_var(this.weightedClose())
      const macdDivr = context.new_var()

      high.get(100)
      low.get(100)
      weightedClose.get(100)
      macdDivr.get(100)
      //#endregion

      //#region Calculate
      const highIndicator = this.wwma(high, masterMa)
      const lowIndicator = this.wwma(low, masterMa)
      const masterIndicator = this.wma(weightedClose, masterMa)

      if (masterIndicator > highIndicator) macdDivr.set(masterIndicator - highIndicator)
      if (masterIndicator < lowIndicator) macdDivr.set(masterIndicator - lowIndicator)

      console.log(macdDivr.get(0), macdDivr.get(1), macdDivr.get(2))
      const signal = this.average(macdDivr, signalMa)

      //#endregion
      return [
        macdDivr.get(0) > 0 && macdDivr.get(0) > macdDivr.get(1) ? macdDivr.get(0) : NaN,
        macdDivr.get(0) > 0 && macdDivr.get(0) < macdDivr.get(1) ? macdDivr.get(0) : NaN,
        macdDivr.get(0) < 0 && macdDivr.get(0) > macdDivr.get(1) ? macdDivr.get(0) : NaN,
        macdDivr.get(0) < 0 && macdDivr.get(0) < macdDivr.get(1) ? macdDivr.get(0) : NaN,

        signal,
      ]
    },
  },

  metainfo: {
    is_price_study: false,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [
      { id: 'macdDivrPositiveUp', type: 'line' },
      { id: 'macdDivrPositiveDown', type: 'line' },
      { id: 'macdDivrNegativeUp', type: 'line' },
      { id: 'macdDivrNegativeDown', type: 'line' },
      { id: 'signal', type: 'line' },
    ],

    defaults: {
      styles: {
        macdDivrPositiveUp: {
          linestyle: 0,
          linewidth: 3,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#66ff1a',
        },

        macdDivrPositiveDown: {
          linestyle: 0,
          linewidth: 3,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ffc34d',
        },
        macdDivrNegativeUp: {
          linestyle: 0,
          linewidth: 2,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#00b300',
        },
        macdDivrNegativeDown: {
          linestyle: 0,
          linewidth: 2,
          plottype: 1,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#cc0000',
        },
        signal: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ff8c00',
        },
      },
      precision: 2,
      inputs: {
        masterMa: 34,
        signalMa: 9,
      },
    },

    styles: {
      macdDivrPositiveUp: {
        title: 'Impluse MACD',
        histogramBase: 0,
        joinPoints: false,
      },
      macdDivrPositiveDown: {
        title: 'Impluse MACD',
        histogramBase: 0,
        joinPoints: false,
      },
      macdDivrNegativeUp: {
        title: 'Impluse MACD',
        histogramBase: 0,
        joinPoints: false,
      },
      macdDivrNegativeDown: {
        title: 'Impluse MACD',
        histogramBase: 0,
        joinPoints: false,
      },
      signal: {
        title: 'Signal',
        histogramBase: 0,
        joinPoints: false,
      },
    },
    inputs: [
      {
        id: 'masterMa',
        name: 'MasterMA',
        defval: 34,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'signalMa',
        name: 'SignalMA',
        defval: 9,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
    ],
  },
})
