import { memo, useEffect } from 'react'
import { Signalr } from '~/modules/SDK/Signalr/Signalr'
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import QuoteValueItem from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody'
import { css } from '@emotion/react'
import {
  fill_horizontal_cross_center,
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { store } from '~/pages/heineken_template/_private/store'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { useSnapshot } from 'valtio'
import { scrollbar2Css } from '~/css/scrollbarCss'

//先隨便寫styled反正一定會改 ㄏㄏ

export const SymbolQuoteList = memo<ReactProps<{ symbol: string[] }>>(function SymbolQuoteList(
  props,
) {
  //試驗性群組商品signalr
  useEffect(() => {
    signalrStore2.addQuote(props.symbol)
    return () => {
      signalrStore2.removeQuote(props.symbol)
    }
  }, [JSON.stringify(props.symbol)])

  const value = signalrHooks2.useQuotes(props.symbol)

  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        ${scrollbar2Css};
        gap: 4px;
        & > * {
          &:hover {
            margin-left: 8px;
          }
        }
      `}
    >
      {value.map((quote, index) => {
        return (
          <QuoteBody
            quote={quote}
            key={index}
          />
        )
      })}
    </div>
  )
})

const QuoteBody = memo<ReactProps<{ quote: Signalr.ValueOfOHLC }>>(function QuoteBody(props) {
  const quote = props.quote
  const change = processQuoteToReadable(props.quote as Signalr.ValueOfOHLC).closeChange

  const charting = useSnapshot(store.charting)
  const currentSymbol = charting.symbol

  const isActive = quote.symbol === currentSymbol
  return (
    <div
      css={css`
        display: grid;
        width: 100%;
        grid-template-columns: 35% 35% 30%;
        grid-template-rows: 100%;
        border-bottom: 1px solid #565656;
        ${isActive ? 'border: 1px solid #fafafa' : null};
        padding: 0px 2px;
        font-size: 14px;
        cursor: pointer;
        transition: 0.3s;
        background-image: linear-gradient(
          to right,
          transparent,
          transparent,
          ${change > 0 ? '#ff000011' : change < 0 ? '#00ff0011' : '#aaaaaa11'}
        );
        border-radius: 2px;
        &:hover {
          background-color: #aaaaaa11;
        }
      `}
      onClick={() => store.charting.changeSymbol(quote.symbol)}
    >
      <div css={fill_horizontal_cross_center}>
        <QuoteValueItem.arrow.Display quote={quote} />
        <div
          css={css`
            ${fill_vertical_all_center};
            align-items: start;
            padding: 0px 0px 0px 16px;
          `}
        >
          <SymbolName symbol={quote.symbol} />
          <span>{quote.symbol}</span>
        </div>
      </div>

      <QuoteValueItem.ohlc.Display
        quote={quote}
        ohlcType={'close'}
      />

      <div
        css={css`
          ${fill_vertical_all_center};
          align-items: start;
          padding: 0px 0px 0px 32px;
        `}
      >
        <QuoteValueItem.change.Display
          quote={quote}
          changeType='closeChange'
        />
        <QuoteValueItem.change.Display
          quote={quote}
          changeType='closeChangePercent'
          unit='%'
        />
      </div>
    </div>
  )
})
