import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss'
import { store } from '../heineken_template/_private/store'
import TitleSymbolQuote from '../../modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import IntradayTrendChart from '~/modules/trendChart'
import { SymbolQuoteList } from './component/SymbolQuoteList'
import { QuoteDetail } from './component/QuoteDetail'
import { AiFillFire, AiFillHeart } from 'react-icons/ai'
import { Badge, Button, Chip, Tabs } from '@mantine/core'
import { css } from '@emotion/react'
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList'
import { GrAdd } from 'react-icons/gr'
import { MdAdd, MdClose } from 'react-icons/md'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2'
import { scrollbar2Css } from '~/css/scrollbarCss'

const symbol = ['TX-1', 'TSEA', 'OTCA', 'TSE13', 'TSE17', 'YM-1', 'NQ-1', 'ES-1', 'CN-1', 'NK-1']
const watchListGroup = 'tc1688_group_1'

export const Win988_SidePane1 = memo<ReactProps>(function Win988_SidePane1() {
  const charting = useSnapshot(store.charting)
  const currentSymbol = charting.symbol

  const { state: watchListState, acts: watchListActs } = useSymbolWatchList(watchListGroup)

  return (
    <styleds.container>
      <TitleSymbolQuote.Default symbol={currentSymbol} />
      <styleds.trendChartContent>
        <IntradayTrendChart
          symbol={currentSymbol}
          ticksSize={12}
          ticksHeight={20}
          priceTicksMargin={-15}
          priceTicksSize={11}
        />
      </styleds.trendChartContent>
      <styleds.watchlistContent>
        <WatchListAddButton
          watchListActs={watchListActs}
          watchListState={watchListState}
          currentSymbol={currentSymbol}
        />
      </styleds.watchlistContent>
      <styleds.quoteDetailContent>
        <QuoteDetail symbol={symbol} />
      </styleds.quoteDetailContent>

      <SymbolDashboard
        watchListActs={watchListActs}
        watchListState={watchListState}
        currentSymbol={currentSymbol}
      />
    </styleds.container>
  )
})

const WatchListAddButton = memo<
  ReactProps<{
    watchListState: any
    watchListActs: any
    currentSymbol: string
  }>
>(function WatchListAddButton(props) {
  const currentSymbol = props.currentSymbol
  const hadSymbol = props.watchListState.symbolListArray?.includes(currentSymbol)

  return (
    <Button
      fullWidth
      size='xs'
      onClick={() => {
        hadSymbol
          ? props.watchListActs.removeSymbol(currentSymbol)
          : props.watchListActs.addSymbol(currentSymbol, 20)
      }}
      color={hadSymbol ? 'red' : 'null'}
      leftIcon={
        hadSymbol ? (
          <MdClose
            size='18px'
            fill={'#fafafa'}
          />
        ) : (
          <MdAdd
            size='18px'
            fill={'#fafafa'}
          />
        )
      }
    >
      <SymbolName symbol={currentSymbol} />
      {currentSymbol}
      &nbsp;
      {hadSymbol ? '移出觀察清單' : '加入觀察清單'}
    </Button>
  )
})

const SymbolDashboard = memo<
  ReactProps<{
    watchListState: any
    watchListActs: any
    currentSymbol: string
  }>
>(function SymbolDashboard(props) {
  return (
    <div
      css={css`
        ${fill_vertical_cross_center};
        height: calc(100% - 348px);
      `}
    >
      <Tabs
        defaultValue='hot'
        css={css`
          width: 100%;
          height: 100%;
          .mantine-1pzasyw[data-active]{
            background-color: #606972;
          }
          .mantine-pmhm1h[data-active]{
            background-color: #606972;
          }
          .         
        `}
      >
        <Tabs.List
          grow
          css={css`
            height: 42px;
          `}
        >
          <Tabs.Tab
            value='hot'
            icon={<AiFillFire />}
          >
            熱門清單
          </Tabs.Tab>
          <Tabs.Tab
            value='watchlist'
            icon={<AiFillHeart />}
            rightSection={
              <Badge
                w={20}
                h={20}
                sx={{ pointerEvents: 'none' }}
                variant='filled'
                size='xs'
                p={0}
              >
                {props.watchListState.symbolListArray?.length}
              </Badge>
            }
          >
            觀察清單
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel
          value='hot'
          pt='xs'
          css={css`
            height: calc(100% - 42px);
          `}
        >
          <styleds.symbolList>
            <SymbolQuoteList symbol={symbol} />
          </styleds.symbolList>
        </Tabs.Panel>

        <Tabs.Panel
          value='watchlist'
          pt='xs'
          css={css`
            height: calc(100% - 42px);
          `}
        >
          <styleds.symbolList>
            <SymbolQuoteList symbol={props.watchListState.symbolListArray} />
          </styleds.symbolList>
        </Tabs.Panel>
      </Tabs>
    </div>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-image: linear-gradient(to bottom, #26272d, #111317);
    padding: 4px;
    gap: 4px;
  `,
  trendChartContent: styled.div`
    height: 148px;
    width: 100%;
  `,
  watchlistContent: styled.div`
    width: 100%;
    display: flex;
    direction: row;
    justify-content: end;
    padding: 0 0 0 24%;
  `,
  quoteDetailContent: styled.div`
    height: 126px;
    width: 100%;
  `,
  symbolList: styled.div`
    height: 100%;
    width: 100%;
  `,
}
