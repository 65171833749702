import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { daddy960_tc1688_atrStopV3 } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_atrStopV3'
import { daddy960_tc1688_heikinAshi } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_heikinAshi'
import { daddy960_tc1688_macdImpluse } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_macdImpluse'
import { daddy960_tc1688_turtle } from '~/trades/indicators/daddy960_tc1688/daddy960_tc1688_turtle'
import { store } from '../heineken_template/_private/store'

const DEFAULT_SYMBOL = 'TXAM-1'

const tc1688Indicators = [
  daddy960_tc1688_heikinAshi,
  daddy960_tc1688_macdImpluse,
  daddy960_tc1688_turtle,
  daddy960_tc1688_atrStopV3,
]

export const daddy960_tc1688_strategyGroup = {
  main: [
    {
      displayName: 'tc1688',
      indicators: [...tc1688Indicators],
      symbol: DEFAULT_SYMBOL,
      interval: 'D',
      panesRatio: 60,
    },
  ] as ChartTypes.StrategyConfig[],
}

export const daddy960_tc1688_initStrategies = () => {
  store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded, ...tc1688Indicators]

  store.charting.initStrategy({
    configs: [...daddy960_tc1688_strategyGroup.main],
  })

  store.charting.changeSymbol(DEFAULT_SYMBOL)
}
