import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const daddy960_tc1688_atrStopV3 = createIndicator({
  id: 'daddy960-tc1688-atrStopV3',
  displayName: 'ATR Stop V3',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const length = this._input(0)
      const atrLength = this._input(1)
      const kv = this._input(2)

      const high = this.ohlc.highArray
      const low = this.ohlc.lowArray
      const close = this.ohlc.closeArray
      const up = context.new_var()
      const down = context.new_var()
      const trend = context.new_var()
      const smax = context.new_var()
      const smin = context.new_var()

      high.get(100)
      low.get(100)
      close.get(100)
      up.get(100)
      down.get(100)
      trend.get(100)
      smax.get(100)
      smin.get(100)

      const atr = kv * this.atr(atrLength)

      const newMin = this.maxList([isNaN(smin.get(0)) ? -100000 : smin.get(0), high.get(1) - atr])
      const newMax = this.minList([isNaN(smax.get(0)) ? 100000 : smin.get(0), low.get(1) + atr])
      smin.set(newMin)
      smax.set(newMax)

      trend.set(trend.get(1))

      console.log(`Trend: ${trend.get(0)} ${trend.get(1)}`)

      if (close.get(0) > smax.get(1)) trend.set(1)

      if (close.get(0) < smin.get(1)) trend.set(-1)

      if (trend.get(0) > 0) {
        if (smin.get(0) < smin.get(1)) smin.set(smin.get(1))

        up.set(smin.get(0))
        down.set(NaN)
      }

      if (trend.get(0) < 0) {
        if (smax.get(0) > smax.get(1)) smax.set(smax.get(1))

        up.set(NaN)
        down.set(smax.get(0))
      }

      return [up.get(0), down.get(0)]
    },
  },
  metainfo: {
    is_price_study: true,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [
      { id: 'up', type: 'line' },
      { id: 'down', type: 'line' },
    ],

    defaults: {
      styles: {
        up: {
          linestyle: 0,
          linewidth: 3,
          plottype: 7,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#0099ff',
        },
        down: {
          linestyle: 0,
          linewidth: 3,
          plottype: 7,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ff5500',
        },
      },
      precision: 2,
      inputs: {
        length: 10,
        atrLength: 5,
        kv: 2.5,
      },
    },

    styles: {
      up: {
        title: 'ATR_S Up',
        histogramBase: 0,
        joinPoints: false,
      },
      down: {
        title: 'ATR_S Down',
        histogramBase: 0,
        joinPoints: false,
      },
    },
    inputs: [
      {
        id: 'length',
        name: 'Length',
        defval: 10,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'atrLength',
        name: 'ATR Length',
        defval: 5,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'kv',
        name: 'KV',
        defval: 2.5,
        type: 'float',
        min: 0.1,
        max: 500,
        step: 0.1,
      },
    ],
  },
})
