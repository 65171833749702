import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const asia71178_gwa = createIndicator({
  id: 'asia71178-gwa',
  displayName: 'GWA',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      //#region Input params

      const p1 = this._input(0)
      const p2 = this._input(1)
      const n1 = this._input(2)
      const n2 = this._input(3)
      const n = this._input(4)
      const p = this._input(5)
      const q = this._input(6)
      const k = this._input(7)

      //#endregion

      //#region prepare series

      const close = this.ohlc.closeArray
      const wma1 = context.new_var()
      const wma2 = context.new_var()
      const rsi1 = context.new_var()
      const rsi2 = context.new_var()
      const ama = context.new_var()
      const mx = context.new_var()
      const amb = context.new_var()
      const ambDiff = context.new_var()
      const ss = context.new_var()
      const priceChange = context.new_var()
      const priceChangeAbs = context.new_var()

      close.get(100)
      wma1.get(100)
      wma2.get(100)
      rsi1.get(100)
      rsi2.get(100)
      ama.get(100)
      mx.get(100)
      amb.get(100)
      ambDiff.get(100)
      ss.get(100)
      priceChange.get(100)
      priceChangeAbs.get(100)

      //#endregion

      //#region Indicators
      const amaValue = this.adaptiveMovingAverage(close, n, p, q)
      ama.get(100)
      const wmaValue1 = this.wma(close, p1)
      const wmaValue2 = this.wma(close, p2)

      priceChange.set(this.maxList([0, close.get(0) - close.get(1)]))
      priceChangeAbs.set(Math.abs(close.get(0) - close.get(1)))
      // console.log('price change:', priceChange.get(0), priceChange.get(1))
      // console.log('price change abs:', priceChangeAbs.get(0), priceChangeAbs.get(1))

      const rsiValue1 = this.average(priceChange, n1) / this.average(priceChangeAbs, n1)
      const rsiValue2 = this.average(priceChange, n2) / this.average(priceChangeAbs, n2)
      wma1.set(wmaValue1)
      wma2.set(wmaValue2)
      rsi1.set(rsiValue1)
      rsi2.set(rsiValue2)
      // console.log(`WMA1=> ${wma1.get(0)} ${wma1.get(1)}`)
      // console.log(`WMA2=> ${wma2.get(0)} ${wma2.get(1)}`)
      // console.log(`RSI1=> ${rsi1.get(0)} ${rsi1.get(1)}`)
      // console.log(`RSI2=> ${rsi2.get(0)} ${rsi2.get(1)}`)

      //#endregion

      //#region AMA color logic
      ama.set(amaValue)
      // console.log(`ama(${ama.mindepth}): ${ama.get(0)}`)

      mx.set(this.lowest(ama, ama.mindepth))
      // console.log(`mx(${mx.mindepth}): ${mx.get(0)}`)

      const my = mx.get(mx.mindepth - 1)
      // console.log(`my: ${my}`)

      amb.set(ama.get(0) + Math.abs(my))
      // console.log(`amb(${amb.mindepth}): ${amb.get(0)}, ${amb.get(1)}`)

      const filter = (k / 100) * this.standardDev(context.new_var(amb.get(0) - amb.get(1)), n)
      // console.log(`filter: ${filter}`)

      ss.set(amb.get(0) - amb.get(1) > 0 ? 1 : amb.get(0) - amb.get(1) < 0 ? -1 : 0)
      // console.log(`ss(${ss.mindepth}): ${ss.get(0)}`)

      let indexOfHigh = -1
      let indexOfLow = -1

      for (let i = 1; i < ama.mindepth - 1; i++) {
        if (ss.get(i) === 1) {
          indexOfHigh = i
          break
        }
      }

      for (let i = 1; i < ama.mindepth - 1; i++) {
        if (ss.get(i) === -1) {
          indexOfLow = i
          break
        }
      }
      // console.log(`index h: ${indexOfHigh}, index l: ${indexOfLow}`)

      const amaHigh = amb.get(indexOfHigh)
      const amaLow = amb.get(indexOfLow)
      // console.log(`
      // amaHigh: ${amaHigh}
      // amaLow: ${amaLow}
      // `)
      const tt = Number(amb.get(0) - amaLow > filter) - Number(amaHigh - amb.get(0) > filter)
      console.log(`tt: ${tt}`)

      //#endregion

      const longFlag =
        rsi1.get(0) > rsi2 && wma1.get(0) > wma2.get(0) && wma1.get(1) <= wma2.get(1) && tt === 1

      const shortFlag =
        rsi1.get(0) < rsi2.get(1) &&
        wma2.get(0) > wma1.get(0) &&
        wma2.get(1) <= wma1.get(1) &&
        tt === -1

      const longFlag2 =
        wma1.get(0) > wma2.get(0) &&
        rsi1.get(0) > rsi2.get(0) &&
        rsi1.get(1) <= rsi2.get(1) &&
        tt === 1

      const shortFlag2 =
        wma1.get(0) < wma2.get(0) &&
        rsi2.get(0) > rsi1.get(0) &&
        rsi2.get(1) <= rsi1.get(1) &&
        tt === -1

      console.log('AMA OUT=> ', ama.get(0))

      return [
        tt === 1 ? amaValue : NaN,
        tt === -1 ? amaValue : NaN,
        tt === 0 ? amaValue : NaN,
        longFlag,
        longFlag2,
        shortFlag,
        shortFlag2,
      ]
    },
  },

  metainfo: {
    is_price_study: true,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [
      { id: 'amaRed', type: 'line' },
      { id: 'amaGreen', type: 'line' },
      { id: 'amaYellow', type: 'line' },

      { id: 'longFlag', type: 'chars' },
      { id: 'longFlag2', type: 'chars' },
      { id: 'shortFlag', type: 'chars' },
      { id: 'shortFlag2', type: 'chars' },
    ],

    defaults: {
      styles: {
        amaRed: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ff1744',
        },

        amaGreen: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#00A600',
        },
        amaYellow: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ffff00',
        },

        longFlag: { color: '#ff1744', textColor: '#ff1744', transparency: 20, visible: true },
        longFlag2: { color: '#ff1744', textColor: '#ff1744', transparency: 20, visible: true },
        shortFlag: { color: '#00A600', textColor: '#00A600', transparency: 20, visible: true },
        shortFlag2: { color: '#00A600', textColor: '#00A600', transparency: 20, visible: true },
      },
      precision: 2,
      inputs: {
        p1: 7,
        p2: 11,
        n1: 60,
        n2: 66,
        n: 10,
        p: 2,
        q: 30,
        k: 15,
      },
    },

    styles: {
      amaRed: {
        title: 'amaRed',
        histogramBase: 0,
        joinPoints: false,
      },
      amaGreen: {
        title: 'amaGreen',
        histogramBase: 0,
        joinPoints: false,
      },
      amaYellow: {
        title: 'MACD',
        histogramBase: 0,
        joinPoints: false,
      },

      longFlag: {
        title: '多訊',
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '多',
      },

      longFlag2: {
        title: '多訊2',
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '多',
      },
      shortFlag: {
        title: '空訊',
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '空',
      },
      shortFlag2: {
        title: '空訊2',
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '空',
      },
    },
    inputs: [
      {
        id: 'p1',
        name: 'P2',
        defval: 7,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'p2',
        name: 'P2',
        defval: 11,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'n1',
        name: 'N1',
        defval: 60,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'n2',
        name: 'N2',
        defval: 66,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'n',
        name: 'N',
        defval: 10,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'p',
        name: 'P',
        defval: 2,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'q',
        name: 'Q',
        defval: 30,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'k',
        name: 'K',
        defval: 15,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
    ],
  },
})
