import { map, sortBy } from 'lodash'
import { memo, useMemo } from 'react'
import { useSnapshot } from 'valtio'
import { perf2Store } from '~/modules/SDK/Perf/perf2Store'
import {
  useVirtualAccountHolding,
  useVirtualTransaction,
  useVirtualTransactionStatement,
} from '~/modules/virtualExchange/useVirtualExchangeResource'
import { staticStore } from '~/pages/heineken_template/_private/staticStore'
import dayAPI from '~/utils/dayAPI'
import { LoadingStatus } from './LoadingStatus'
import { strategyPerformanceStyleds } from './strategyPerformanceStyled'
import type { Trade } from '~/modules/SDK/Trade/Trade'
import { Dayjs } from 'dayjs'
import { css } from '@emotion/react'
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss'

type MonthlyStatsticsItem = {
  month: string
  totalNumberOfTrades: number
  pnl: number
}

const getIntradayClosedTrades = (
  closedTrades: Trade.Statement[],
  intraday: Dayjs,
): Trade.Statement[] => {
  const intraDate = dayAPI(intraday).format('MM-DD')
  return closedTrades.filter(trade => dayAPI(trade.datetime).format('MM-DD') === intraDate)
}

const getMonthlyStatistics = (closedTrades: Trade.Statement[]): MonthlyStatsticsItem[] => {
  const monthlyStatistics: {
    [month: string]: MonthlyStatsticsItem
  } = {}

  closedTrades.forEach(trade => {
    const datetime = dayAPI(trade.datetime)
    const month = `${datetime.year()}/${(datetime.month() + 1).toLocaleString()}`

    if (!monthlyStatistics[month]) {
      monthlyStatistics[month] = {
        month: month,
        totalNumberOfTrades: 0,
        pnl: 0,
      }
    }
    monthlyStatistics[month].totalNumberOfTrades++
    monthlyStatistics[month].pnl += trade.pnL + trade.commission + trade.tax
  })
  return Object.values(monthlyStatistics)
}

const ACCOUNT_NAME = 'mengs_test'
const SYMBOL = 'TX-1'

/** 交易詳細資料與結論 */
export const GodPerformance = memo<ReactProps>(function GodPerformance() {
  const intraday = useSnapshot(staticStore).tradedDate.intraday
  const intraDate = dayAPI(intraday).format('MM-DD')

  //const executions = (useVirtualTransaction(ACCOUNT_NAME, { symbol: SYMBOL }) ?? []).reverse()
  const positions =
    useVirtualAccountHolding(ACCOUNT_NAME)?.filter(aggP => aggP.symbol === SYMBOL) ?? []
  const position = positions.find(p => p.symbol === SYMBOL)
  const closedTrades = useVirtualTransactionStatement(ACCOUNT_NAME, { symbol: SYMBOL }) ?? []

  const monthlyStatistics = getMonthlyStatistics(closedTrades)
  const intradayTrades = getIntradayClosedTrades(closedTrades, intraday)

  const loadingState = closedTrades.length > 0
  return (
    <strategyPerformanceStyleds.container>
      {/* Open Interest */}
      <strategyPerformanceStyleds.title backgroundFill='#1976d2'>
        部位資訊
      </strategyPerformanceStyleds.title>

      <strategyPerformanceStyleds.openPositionTableHeader
        css={css`
          grid-template-columns: repeat(4, 25%);
        `}
      >
        <strategyPerformanceStyleds.value>部位方向</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>數量</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>均價</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>浮動損益</strategyPerformanceStyleds.value>
      </strategyPerformanceStyleds.openPositionTableHeader>

      <strategyPerformanceStyleds.closedTradeTableContent
        css={css`
          ${fill_horizontal_all_center};
          height: 36px;
          display: grid;
          grid-template-columns: repeat(4, 25%);
          & > div {
            ${fill_horizontal_all_center};
          }
        `}
      >
        <strategyPerformanceStyleds.position value={position === undefined ? 0 : position?.qty}>
          {position === undefined
            ? '--'
            : position.qty > 0
            ? '做多'
            : position.qty < 0
            ? '做空'
            : ''}
        </strategyPerformanceStyleds.position>

        <strategyPerformanceStyleds.position value={position?.qty ?? 0}>
          {position === undefined ? '--' : position.qty}
        </strategyPerformanceStyleds.position>

        <strategyPerformanceStyleds.position value={position?.averagePrice ?? 0}>
          {position?.averagePrice}
        </strategyPerformanceStyleds.position>

        <strategyPerformanceStyleds.position value={position?.pnl ?? 0}>
          {position?.pnl}
        </strategyPerformanceStyleds.position>
      </strategyPerformanceStyleds.closedTradeTableContent>

      {/* 當日交易明細 */}
      <strategyPerformanceStyleds.title backgroundFill='#1976d2'>
        {intraDate.replace('-', '/')}當日平倉明細
      </strategyPerformanceStyleds.title>

      <strategyPerformanceStyleds.closedTradeTableHeader>
        <strategyPerformanceStyleds.value>平倉時間</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>部位</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>開倉價</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>平倉價</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>損益</strategyPerformanceStyleds.value>
      </strategyPerformanceStyleds.closedTradeTableHeader>

      <strategyPerformanceStyleds.intradayContent>
        {loadingState &&
          intradayTrades.reverse().map((trade, index) => {
            const positionDisplay =
              trade.openPositionQty > 0 ? '做多' : trade.openPositionQty < 0 ? '做空' : ''
            const originalPnL = trade.pnL + trade.commission + trade.tax
            return (
              <strategyPerformanceStyleds.positionBody key={index}>
                <strategyPerformanceStyleds.value>
                  {dayAPI(trade.datetime).toISOString().slice(11, 18)}
                </strategyPerformanceStyleds.value>
                <strategyPerformanceStyleds.value>
                  <strategyPerformanceStyleds.position value={trade.openPositionQty}>
                    {positionDisplay}
                  </strategyPerformanceStyleds.position>
                </strategyPerformanceStyleds.value>
                <strategyPerformanceStyleds.value>
                  {trade.openPositionAveragePrice}
                </strategyPerformanceStyleds.value>
                <strategyPerformanceStyleds.value>
                  {trade.closePositionAveragePrice}
                </strategyPerformanceStyleds.value>
                <strategyPerformanceStyleds.profit value={originalPnL}>
                  {originalPnL}
                </strategyPerformanceStyleds.profit>
              </strategyPerformanceStyleds.positionBody>
            )
          })}
        {!loadingState && <LoadingStatus />}
      </strategyPerformanceStyleds.intradayContent>

      {/* 近月累積績效 */}
      <strategyPerformanceStyleds.title backgroundFill='#722c2c'>
        近月累積績效
      </strategyPerformanceStyleds.title>
      <strategyPerformanceStyleds.monthProfitHeader>
        <strategyPerformanceStyleds.value>交易月份</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>交易筆數</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>總獲利</strategyPerformanceStyleds.value>
      </strategyPerformanceStyleds.monthProfitHeader>

      <strategyPerformanceStyleds.monthInfoContent>
        {loadingState &&
          monthlyStatistics.map((item, index) => {
            return (
              <strategyPerformanceStyleds.monthProfitBody key={index}>
                <div>{item.month.replace('-', '/')}</div>
                <div>{item.totalNumberOfTrades}</div>
                <strategyPerformanceStyleds.profit value={item.pnl}>
                  {item.pnl}
                </strategyPerformanceStyleds.profit>
              </strategyPerformanceStyleds.monthProfitBody>
            )
          })}
        {!loadingState && <LoadingStatus />}
      </strategyPerformanceStyleds.monthInfoContent>

      {/* 歷史交易明細 */}
      <strategyPerformanceStyleds.title backgroundFill='#722c2c'>
        歷史交易明細
      </strategyPerformanceStyleds.title>
      <strategyPerformanceStyleds.closedTradeTableHeader>
        <strategyPerformanceStyleds.value>交易日</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>部位</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>開倉價</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>平倉價</strategyPerformanceStyleds.value>
        <strategyPerformanceStyleds.value>損益</strategyPerformanceStyleds.value>
      </strategyPerformanceStyleds.closedTradeTableHeader>

      <strategyPerformanceStyleds.closedTradeTableContent>
        {loadingState &&
          closedTrades?.map((trade, index) => {
            return (
              <strategyPerformanceStyleds.positionBody key={index}>
                <strategyPerformanceStyleds.value>
                  {trade.datetime.slice(5, 10).replace('-', '/')}
                </strategyPerformanceStyleds.value>

                <strategyPerformanceStyleds.value>
                  <strategyPerformanceStyleds.position value={trade.openPositionQty}>
                    {trade.openPositionQty > 0 ? '做多' : trade.openPositionQty < 0 ? '做空' : ''}
                  </strategyPerformanceStyleds.position>
                </strategyPerformanceStyleds.value>

                <strategyPerformanceStyleds.value>
                  {trade.openPositionAveragePrice}
                </strategyPerformanceStyleds.value>

                <strategyPerformanceStyleds.value>
                  {trade.closePositionAveragePrice}
                </strategyPerformanceStyleds.value>

                <strategyPerformanceStyleds.profit value={trade.pnL}>
                  {trade.pnL + trade.commission + trade.tax}
                </strategyPerformanceStyleds.profit>
              </strategyPerformanceStyleds.positionBody>
            )
          })}

        {!loadingState && <LoadingStatus />}
      </strategyPerformanceStyleds.closedTradeTableContent>
    </strategyPerformanceStyleds.container>
  )
})
