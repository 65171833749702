import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const asia71178_circle = createIndicator({
  id: 'asia71178-circle',
  displayName: '球訊',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const close = this.ohlc.closeArray
      close.get(100)

      const resolution = context.symbol.period
      const length = this._input(0)
      const rsvt = this._input(1)
      const kt = this._input(2)

      const stochastic =
        resolution === '1D'
          ? this.stochastic(length, rsvt, kt)
          : this.stochastic(length * 5, rsvt * 5, kt * 5)

      const value = stochastic.k - stochastic.d

      const value_array = this._context.new_var(value)
      const value_nn = this._context.new_var()
      //const value_green = this._context.new_var()

      value_nn.get(5)
      value_array.get(5)

      const limit = this._context.new_var()

      if (value_array.get(0) >= 0 && value_array.get(1) < 0) {
        value_nn.set(isNaN(limit.get(0)) ? 0 : limit.get(0))
        console.log('>cross', value_nn.get(0))
      }
      if (value_array.get(0) < 0 && value_array.get(1) >= 0) {
        value_nn.set(isNaN(limit.get(0)) ? 0 : limit.get(0))
      }

      if (value >= 0) {
        value_nn.set(value_nn.get(0) + 1)
        limit.set(value_nn.get(0))
      }
      if (value < 0) {
        value_nn.set(value_nn.get(0) - 1)
        limit.set(value_nn.get(0))
      }

      const up = this._context.new_var()
      const dn = this._context.new_var()

      if (value >= 0) {
        up.set(value_nn.get(0))
        dn.set(NaN)
      } else {
        up.set(NaN)
        dn.set(value_nn.get(0))
      }

      return [up.get(0), dn.get(0)]
    },
  },
  metainfo: {
    is_price_study: false,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [
      { id: 'k', type: 'line' },
      { id: 'd', type: 'line' },
    ],
    styles: {
      k: {
        title: 'K',
        histogramBase: 0,
        joinPoints: false,
      },
      d: {
        title: 'D',
        histogramBase: 0,
        joinPoints: false,
      },
    },
    defaults: {
      inputs: {
        length: 8,
        rsvt: 8,
        kt: 12,
      },
      styles: {
        k: {
          linestyle: 0,
          linewidth: 2,
          plottype: 6,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ff0000',
        },
        d: {
          linestyle: 0,
          linewidth: 2,
          plottype: 6,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#00aa00',
        },
      },
      precision: 2,
    },

    inputs: [
      {
        id: 'length',
        name: 'Length',
        defval: 8,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'rsvt',
        name: 'RSVt',
        defval: 8,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'kt',
        name: 'Kt',
        defval: 12,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
    ],
  },
})
