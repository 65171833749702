import styled from '@emotion/styled'
import { memo } from 'react'
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss'

export const Revive_SidePane2 = memo<ReactProps>(function Revive_SidePane2() {
  return <styleds.container>2</styleds.container>
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-color: #282a31;
  `,
}
