import { css } from '@emotion/react'
import { memo } from 'react'
import { useSnapshot } from 'valtio'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { asia71178_store } from '../asia71178_store'

export const PriceBuild = memo<ReactProps>(function PriceBuild() {
  const state = useSnapshot(asia71178_store)

  const valueUp = isNaN(state.buildDn) ?'-':state.buildDn.toFixed(2)
  const valueDn = isNaN(state.buildUp) ? '-':state.buildUp.toFixed(2)
  return (
    <div
      css={css`
        ${fill_horizontal_cross_center};
        gap: 16px;
        padding: 0 16px;
        font-size: 16px;
      `}
    >
      <span>集結區:</span>
      {valueUp} ~ {valueDn}
    </div>
  )
})
