import { createIndicator } from '~/modules/SDK/indicator/createIndicator'

export const daddy960_tc1688_heikinAshi = createIndicator({
  id: 'daddy960-tc1688-heikinAshi',
  displayName: 'Heikin Ashi',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const maLength = this._input(0)

      const open = this.ohlc.openArray
      const high = this.ohlc.highArray
      const low = this.ohlc.lowArray
      const close = this.ohlc.closeArray

      const heikinOpen = context.new_var()
      const heikinClose = context.new_var()

      open.get(100)
      close.get(100)

      const avgOpen = (heikinOpen.get(1) + heikinClose.get(1)) / 2
      const avgClose = (open.get(0) + high.get(0) + low.get(0) + close.get(0)) * 0.25

      const heikinHigh = this.maxList([high.get(0), avgOpen, avgClose])
      const heikinLow = this.minList([low.get(0), avgOpen, avgClose])
      const ma = this.average(close, maLength)

      if (isNaN(avgOpen)) {
        heikinOpen.set(open.get(1))
      } else {
        heikinOpen.set(avgOpen)
      }
      if (isNaN(avgClose)) {
        heikinClose.set(close.get(1))
      } else {
        heikinClose.set(avgClose)
      }

      const colorIndex = heikinClose.get(0) > heikinOpen.get(0) ? 1 : -1
      return [ma, heikinOpen.get(0), heikinHigh, heikinLow, heikinClose.get(0), colorIndex]
    },
  },
  metainfo: {
    defaults: {
      styles: {
        movAvg: {
          linestyle: 0,
          linewidth: 1,
          plottype: 0,
          trackPrice: !1,
          transparency: 0,
          visible: !0,
          color: '#00ffff',
        },
      },
      ohlcPlots: {
        plotcandle_0: {
          borderColor: '#ffffff',
          color: '#000000',
          drawBorder: false,
          drawWick: true,
          plottype: 'ohlc_candles',
          visible: true,
          wickColor: '#737375',
        },
      },
      palettes: {
        barPalette: {
          colors: [{ color: '#6aa584' }, { color: '#f8f8f9' }],
        },
      },
      inputs: { maLength: 6 },
    },
    plots: [
      {
        id: 'movAvg',
        type: 'line',
      },
      {
        id: 'plot_1',
        type: 'ohlc_open',
        target: 'plotcandle_0',
      },
      {
        id: 'plot_2',
        type: 'ohlc_high',
        target: 'plotcandle_0',
      },
      {
        id: 'plot_3',
        type: 'ohlc_low',
        target: 'plotcandle_0',
      },
      {
        id: 'plot_4',
        type: 'ohlc_close',
        target: 'plotcandle_0',
      },
      {
        id: 'plot_5',
        type: 'ohlc_colorer',
        palette: 'barPalette',
        target: 'plotcandle_0',
      },
    ],
    styles: {
      movAvg: {
        title: 'ma',
        histogramBase: 0,
        joinPoints: false,
      },
    },
    palettes: {
      barPalette: {
        colors: [{ name: 'Up Color' }, { name: 'Down Color' }],
        valToIndex: {
          '1': 0,
          '-1': 1,
        },
      },
    },
    ohlcPlots: {
      plotcandle_0: {
        title: 'Candles',
      },
    },

    is_price_study: true,
    inputs: [
      {
        id: 'maLength',
        name: 'Mov Avg Length',
        defval: 6,
        type: 'integer',
        min: 1,
        max: 2e3,
      },
    ],
    scriptIdPart: '',
  },
})
