import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import dayAPI from '~/utils/dayAPI'
import { crocodiles, CrocodilesParams } from './crocodiles'

export const god_positionShort = createIndicator({
  id: 'god-positionShort',
  displayName: '波段策略(空)',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      const _calcIndicator = (params: CrocodilesParams) => {
        const { biasLength, percentRLength, rsiLength, kdLength } = params
        const series = context.new_var(
          crocodiles(
            resolution as '1' | '5' | '15',
            this.bias(biasLength),
            this.percentR(percentRLength),
            this.rsi(close, rsiLength),
            this.stochastic(kdLength, 3, 3).k,
          ),
        )
        series.get(100)
        return series
      }

      const resolution = context.symbol.period
      const MAX_ENTRIES_TODAY = 5
      const STOP_LOSS = 60
      const TAKE_PROFIT = 120

      const longThreshold1 = 15
      const shortThreshold1 = 80
      const longThreshold5 = 30
      const shortThreshold5 = 75
      const longThreshold15 = 20
      const shortThreshold15 = 80

      //#region Prepare data
      const position = context.new_var()
      const entryPrice = context.new_var()
      const exitPrice = context.new_var()
      const entriesToday = context.new_var()

      const close = this.ohlc.closeArray
      const high = this.ohlc.highArray
      const low = this.ohlc.lowArray
      const volume = context.new_var(this.PineJS.Std.volume(context))
      const datetime = dayAPI(this.PineJS.Std.time(context))

      position.get(1)
      entriesToday.get(100)
      entryPrice.get(100)
      exitPrice.get(100)

      close.get(100)
      high.get(100)
      low.get(100)
      volume.get(100)

      if (datetime.hour() === 8 && datetime.minute() === 45) {
        entriesToday.set(0)
      }

      //#endregion

      //#region Calc indicators

      const biasLength = 12
      const percentRLength = 13
      const rsiLength = 6
      const kdLength = 9

      //#region Params 1Min
      let params1Min: CrocodilesParams = {
        biasLength: 0,
        percentRLength: 0,
        rsiLength: 0,
        kdLength: 0,
      }
      if (resolution === '1') {
        params1Min = {
          biasLength: biasLength,
          percentRLength: percentRLength,
          rsiLength: rsiLength,
          kdLength: kdLength,
        }
      } else if (resolution === '5') {
        params1Min = {
          biasLength: Math.ceil(biasLength / 5),
          percentRLength: Math.ceil(percentRLength / 5),
          rsiLength: Math.ceil(rsiLength / 5),
          kdLength: Math.ceil(kdLength / 5),
        }
      } else if (resolution === '15') {
        params1Min = {
          biasLength: Math.ceil(biasLength / 15),
          percentRLength: Math.ceil(percentRLength / 15),
          rsiLength: Math.ceil(rsiLength / 15),
          kdLength: Math.ceil(kdLength / 15),
        }
      }
      //#endregion

      //#region Params 5Min
      let params5Min: CrocodilesParams = {
        biasLength: 0,
        percentRLength: 0,
        rsiLength: 0,
        kdLength: 0,
      }
      if (resolution === '1') {
        params5Min = {
          biasLength: biasLength * 5,
          percentRLength: percentRLength * 5,
          rsiLength: rsiLength * 5,
          kdLength: kdLength * 5,
        }
      } else if (resolution === '5') {
        params5Min = {
          biasLength: biasLength,
          percentRLength: percentRLength,
          rsiLength: rsiLength,
          kdLength: kdLength,
        }
      } else if (resolution === '15') {
        params5Min = {
          biasLength: Math.ceil(biasLength / 3),
          percentRLength: Math.ceil(percentRLength / 3),
          rsiLength: Math.ceil(rsiLength / 3),
          kdLength: Math.ceil(kdLength / 3),
        }
      }
      //#endregion

      //#region Params 15Min
      let params15Min: CrocodilesParams = {
        biasLength: 0,
        percentRLength: 0,
        rsiLength: 0,
        kdLength: 0,
      }
      if (resolution === '1') {
        params15Min = {
          biasLength: biasLength * 15,
          percentRLength: percentRLength * 15,
          rsiLength: rsiLength * 15,
          kdLength: kdLength * 15,
        }
      } else if (resolution === '5') {
        params15Min = {
          biasLength: biasLength * 3,
          percentRLength: percentRLength * 3,
          rsiLength: rsiLength * 3,
          kdLength: kdLength * 3,
        }
      } else if (resolution === '15') {
        params15Min = {
          biasLength: biasLength * 15,
          percentRLength: percentRLength * 15,
          rsiLength: rsiLength * 15,
          kdLength: kdLength * 15,
        }
      }
      //#endregion

      const indicator1Min = _calcIndicator(params1Min)
      const indicator5Min = _calcIndicator(params5Min)
      const indicator15Min = _calcIndicator(params15Min)
      //#endregion

      //#region Conditions
      const longEntry =
        indicator1Min.get(0) < longThreshold1 &&
        indicator5Min.get(0) < longThreshold5 &&
        indicator15Min.get(0) < longThreshold15

      const shortEntry =
        indicator1Min.get(0) > shortThreshold1 &&
        indicator5Min.get(0) > shortThreshold5 &&
        indicator15Min.get(0) > shortThreshold15 &&
        entriesToday.get(0) < MAX_ENTRIES_TODAY

      const settlement = this.isSettlementDay() && datetime.hour() > 13
      //#endregion

      let entryShortFlag = NaN
      let exitShortFlag = NaN

      if (shortEntry) {
        position.set(-1)
      }

      // ---------------------------------------- Entry ----------------------------------------

      if (position.get(0) === -1 && position.get(1) !== -1) {
        entryShortFlag = 1
        entryPrice.set(close.get(0))
        entriesToday.set(entriesToday.get(0) + 1)
      }

      // ---------------------------------------- Exit ----------------------------------------

      if (position.get(0) === -1 && position.get(1) === -1) {
        //固定停利
        if (low.get(0) < entryPrice.get(1) - TAKE_PROFIT) {
          exitShortFlag = 1
          position.set(0)
          exitPrice.set(entryPrice.get(1) - TAKE_PROFIT)
        }

        // stop loss
        if (high.get(0) > entryPrice.get(1) + STOP_LOSS) {
          exitShortFlag = 1
          position.set(0)
          exitPrice.set(entryPrice.get(1) + STOP_LOSS)
        }
        // exit
        if (longEntry) {
          exitShortFlag = 1
          position.set(0)
          exitPrice.set(close.get(0))
        }

        //settlement
        if (settlement) {
          exitShortFlag = 1
          position.set(0)
          exitPrice.set(close.get(0))
        }
      }

      return [entryShortFlag, exitShortFlag]
    },
  },
  metainfo: {
    is_price_study: true,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    inputs: [],
    plots: [
      { id: 'entryFlagShort', type: 'chars' },
      { id: 'exitFlagShort', type: 'chars' },
    ],
    defaults: {
      precision: 2,
      inputs: {},
      styles: {
        entryFlagShort: { color: '#5FFA6D', textColor: '#5FFA6D', transparency: 20, visible: true },
        exitFlagShort: { color: '#5FFA6D', textColor: '#5FFA6D', transparency: 20, visible: true },
      },
    },
    styles: {
      entryFlagShort: {
        title: '進場點(空)',
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '空單進場',
      },
      exitFlagShort: {
        title: '出場點(空)',
        isHidden: false,
        location: 'BelowBar',
        char: '*',
        size: 'small',
        text: '空單出場',
      },
    },
  },
})
