import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2'
import { store } from '~/pages/heineken_template/_private/store'
import QuoteValueItem from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'

export const QuoteDetail = memo<ReactProps<{ symbol: string[] }>>(function QuoteDetail(props) {
  const charting = useSnapshot(store.charting)
  const currentSymbol = charting.symbol

  useEffect(() => {
    signalrStore2.addQuote(currentSymbol)
    return () => {
      signalrStore2.removeQuote(currentSymbol)
    }
  }, [JSON.stringify(currentSymbol)])

  const value = useSnapshot(signalrStore2.values.quote)[currentSymbol]
  return (
    <styleds.container>
      <span>成交</span>
      <QuoteValueItem.ohlc.Display
        quote={value}
        ohlcType={'close'}
      />
      <span>單量</span>
      <QuoteValueItem.ohlc.Display
        quote={value}
        ohlcType={'qty'}
      />
      <span>買進</span>
      <QuoteValueItem.ohlc.Display
        quote={value}
        ohlcType={'bid'}
      />
      <span>賣出</span>
      <QuoteValueItem.ohlc.Display
        quote={value}
        ohlcType={'ask'}
      />
      <span>最高</span>
      <QuoteValueItem.ohlc.Display
        quote={value}
        ohlcType={'high'}
      />
      <span>最低</span>
      <QuoteValueItem.ohlc.Display
        quote={value}
        ohlcType={'low'}
      />
      <span>開盤</span>
      <QuoteValueItem.ohlc.Display
        quote={value}
        ohlcType={'open'}
      />
      <span>參考</span>
      <QuoteValueItem.ohlc.Display
        quote={value}
        ohlcType={'prevRef'}
      />
      <span>總量</span>
      <QuoteValueItem.ohlc.Display
        quote={value}
        ohlcType={'volume'}
      />
      <span>漲跌</span>
      <QuoteValueItem.change.Display
        quote={value}
        changeType='closeChange'
      />
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 25% 25% 25% 25%;
    background-color: #26272c;
    border-radius: 4px;
    padding: 2px;
    font-size: 14px;
    & > div {
      ${fill_horizontal_cross_center};
      justify-content: start;
      height: 24px;
    }
    & > span {
      ${fill_horizontal_cross_center};
      justify-content: center;
      height: 24px;
    }
  `,
}
