import styled from '@emotion/styled'
import { memo } from 'react'
import {
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'

export const Revive_SidePane1 = memo<ReactProps>(function Revive_SidePane1() {
  return (
    <styleds.container>
      <styleds.card height={30}>30</styleds.card>
      <styleds.card height={180}>180</styleds.card>
      <styleds.card height={40}>40</styleds.card>
      <styleds.card height={500}>500</styleds.card>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    background-color: #282a31;
    padding: 8px;
    gap: 4px;
  `,
  card: styled.div<{ height: number }>`
    ${fill_vertical_all_center};
    height: ${props => props.height}px;
    background-color: #25262a;
    border-radius: 8px;
  `,
}
